<ng-container *ngIf="productDetails$ | async as product">
    <ng-container *ngIf="productOption$ | async as options">
        <!-- OBSOLETE -->
        <div *ngIf="options?.obsoleteFlag; else showPrice" class="row mt-4">
            <div class="col-12">{{"productDetail.obsolete" | cxTranslate}}</div>
        </div>
        <ng-template #showPrice>
            <!-- PRICE AVAILABLE -->
            <ng-container *ngIf="options?.quotationFlag === false; else displayQuotation">
                <!-- WITH DISCOUNT -->
                <ng-container *ngIf="options.discountPercentage > 0; else standardPrice">
                    <!-- ORIGINAL PRICE -->
                    <div class="row mt-4 mb-2">
                        <div class="col-6">{{"productDetail.price" | cxTranslate}}</div>
                        <div class="col-6 text-right">
                            {{options.priceData?.value | currencyPrice}} 
                            {{options.priceData?.currencyIso | currencyFormat}}
                        </div>
                    </div>
                    <!-- DISCOUNT PERCENTAGE -->
                    <div class="row mb-2">
                        <div class="col-6">{{"productDetail.yourDiscount" | cxTranslate}}</div>
                        <div class="col-6 text-right">{{options.discountPercentage}} %</div>
                    </div>
                    <!-- DISCOUNTED PRICE -->
                    <div class="row">
                        <div class="col-6">{{"productDetail.yourPrice" | cxTranslate}}</div>
                        <div class="col-6 text-right">
                            {{options.discountPriceData?.value |currencyPrice}} 
                            {{options.discountPriceData?.currencyIso | currencyFormat}}
                        </div>
                    </div>
                </ng-container>
                <!-- WITHOUT DISCOUNT -->
                <ng-template #standardPrice>
                    <div class="row mt-4">
                        <div class="col-6">{{"productDetail.price" | cxTranslate}}</div>
                        <div class="col-6 text-right">
                            {{options?.priceData?.value | currencyPrice}} 
                            {{options?.priceData?.currencyIso | currencyFormat}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>
            <!-- PRICE NOT AVAILABLE -->
            <ng-template #displayQuotation>
                <div class="row mt-4 mb-2">
                    <div class="col-6">{{"productDetail.price" | cxTranslate}}</div>
                    <div class="col-6 text-right">{{"productDetail.notAvailable" | cxTranslate}}</div>
                </div>
            </ng-template>
        </ng-template>
    </ng-container>
</ng-container>