import { Component, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Options, Price } from '../../models/product-info';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-product-sum-price',
  templateUrl: './product-sum-price.component.html',
  styleUrl: './product-sum-price.component.scss'
})
export class ProductSumPriceComponent implements OnInit {

  productDetails$: Observable<any>;
  productOption$: Observable<Options>;

  constructor(
    private productsService: ProductDetailsService
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getProduct();
    this.productOption$ = this.productsService.variant$;
  }
}